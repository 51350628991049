<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
              >組織資料設定</b-breadcrumb-item
            >
            <b-breadcrumb-item active>組織參數管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
          {{ `${organization.name}組織參數管理` }}
        </h4>
        <div
          class="col-12 col-xl-6 d-flex align-items-start flex-xl-row-reverse"
        >
          <b-button
            v-if="hasCreatePermission()"
            variant="primary"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            :to="getCreateRoute()"
          >
            新增組織參數
          </b-button>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="organizationVariables"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <span v-if="data.item.status === 'enabled'">
                    <b-button
                      v-if="hasModifyPermission()"
                      class="ml-2"
                      variant="inverse-info"
                      :to="{
                        name: 'OrganizationVariableConfig',
                        params: {
                          orgId,
                          organization_variable_id: data.item.id,
                        },
                      }"
                    >
                      <span class="mdi mdi-settings"></span>
                      設定
                    </b-button>
                    <b-button
                      variant="inverse-primary"
                      :to="getShowRoute(data.item)"
                    >
                      <span class="mdi mdi-eye"></span>
                      查看
                    </b-button>
                    <b-button
                      v-if="hasModifyPermission()"
                      class="ml-2"
                      variant="inverse-warning"
                      :to="getEditRoute(data.item)"
                    >
                      <span class="mdi mdi-lead-pencil"></span>
                      編輯
                    </b-button>
                    <b-button
                      v-if="hasModifyPermission()"
                      class="ml-2"
                      variant="inverse-danger"
                      @click="handleDelete(data.item)"
                    >
                      <span class="mdi mdi-delete"></span>
                      刪除
                    </b-button>
                  </span>
                  <span v-else>
                    <b-button
                      v-if="hasModifyPermission()"
                      variant="primary"
                      @click="handleActivate(data.item)"
                    >
                      開通
                    </b-button>
                  </span>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="10"
            @change="getOranizationVariables"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as consts from "@/consts";
import organizationVariableApi from "../../../apis/organization-variable";
import PermissionChecker from "@/utils/PermissionChecker";
import _ from "lodash";

export default {
  data() {
    return {
      consts,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      showLoadingUpload: false,
      organizationVariables: [],
      fields: [
        {
          key: "key",
          label: "Key",
          sortable: true,
        },
        {
          key: "name",
          label: "名稱",
        },
        {
          key: "value",
          label: "Value",
          formatter: (x) =>
            _.isString(x) && x.length > 20 ? x.substring(0, 20) + "..." : x,
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    // 組織參數管理元件是由 OrganizationVariableList, OrganizationVariableListByMerchant 共用
    isByMerchant() {
      return !!this.$route.params.merchant_id;
    },
    orgId() {
      return this.$route.params.orgId;
    },
    merchantId() {
      return this.$route.params.merchant_id;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getOranizationVariables();
    },
    async getOranizationVariables(page) {
      this.showLoading = true;
      try {
        const { data } = await organizationVariableApi.list({
          page: page || this.currentPage,
          org_code: this.organization.id,
          merchant_id: this.merchantId,
        });
        this.organizationVariables = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得組織參數錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    hasCreatePermission() {
      const permission = this.isByMerchant
        ? this.$permissions.consts.ORGANIZATION_MERCHANT_VARIABLE_CREATE
        : this.$permissions.consts.ORGANIZATION_VARIABLE_CREATE;
      return this.$permissions.has(permission);
    },
    hasModifyPermission() {
      const permission = this.isByMerchant
        ? this.$permissions.consts.ORGANIZATION_MERCHANT_VARIABLE_MODIFY
        : this.$permissions.consts.ORGANIZATION_VARIABLE_MODIFY;
      return this.$permissions.has(permission);
    },
    getCreateRoute() {
      return this.isByMerchant
        ? { name: "OrganizationVariableCreateByMerchant" }
        : { name: "OrganizationVariableCreate" };
    },
    getShowRoute(variable) {
      return this.isByMerchant
        ? {
            name: "OrganizationVariableShowByMerchant",
            params: { organization_variable_id: variable.id },
          }
        : {
            name: "OrganizationVariableShow",
            params: { organization_variable_id: variable.id },
          };
    },
    getEditRoute(variable) {
      return this.isByMerchant
        ? {
            name: "OrganizationVariableEditByMerchant",
            params: { organization_variable_id: variable.id },
          }
        : {
            name: "OrganizationVariableEdit",
            params: { organization_variable_id: variable.id },
          };
    },
    async handleDelete(variable) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>Key 名稱：${variable.key}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            this.deleteVariable(variable);
          }
        });
    },
    async deleteVariable(variable) {
      let response = await organizationVariableApi.remove({
        org_code: this.organization.id,
        organizationVariable: variable,
      });
      if (response.status === 200) {
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "成功刪除 " + variable.key,
        });
        this.getOranizationVariables(1);
      } else {
        if (response.data.message) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: response.data.message,
          });
        }
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    // eslint-disable-next-line no-unused-vars
    async handleActivate(variable) {
      const response = await organizationVariableApi.activate(
        this.organization.id,
        {
          module_config_mapping_id: variable.module_config_mapping_id,
          ...(this.merchantId && { merchant_id: this.merchantId }),
        }
      );
      if (response.status === 200) {
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "成功開通 " + variable.key,
        });
        this.getOranizationVariables(1);
      } else {
        if (response.data.message) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: response.data.message,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
