import https from "./https";

const organizationVariable = {
  list({page, org_code, merchant_id}) {
    let params = {page}
    if (!org_code) {
      return Promise.reject('org_code not found.')
    }
    if (!merchant_id) {
      return https.get(`/admin/organizations/${org_code}/variables`, {params});
    }

    return https.get(`/admin/organizations/${org_code}/merchants/${merchant_id}/variables`, {params});
  },

  get({org_code, organization_variable_id}) {
    return https.get(`/admin/organizations/${org_code}/variables/${organization_variable_id}`);
  },

  getByKey({org_code, key}) {
    return https.get(`/admin/organizations/${org_code}/variables-by-key/${key}`);
  },

  update({org_code, organizationVariable}) {
    return https.put(`/admin/organizations/${org_code}/variables/${organizationVariable.id}`, organizationVariable);
  },

  store({org_code, organizationVariable}) {
    return https.post(`/admin/organizations/${org_code}/variables`, organizationVariable);
  },

  remove({org_code, organizationVariable}) {
    return https.delete(`/admin/organizations/${org_code}/variables/${organizationVariable.id}`);
  },

  activate(orgId, params) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    return https.post(
      `/admin/organizations/${orgId}/variables/activate`,
      params
    );
  }
};

export default organizationVariable;
