var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'OrganizationHome',
            }}},[_vm._v("組織資料設定")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("組織參數管理")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold"},[_vm._v(" "+_vm._s(((_vm.organization.name) + "組織參數管理"))+" ")]),_c('div',{staticClass:"col-12 col-xl-6 d-flex align-items-start flex-xl-row-reverse"},[(_vm.hasCreatePermission())?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary","to":_vm.getCreateRoute()}},[_vm._v(" 新增組織參數 ")]):_vm._e()],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.organizationVariables,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [(data.item.status === 'enabled')?_c('span',[(_vm.hasModifyPermission())?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-info","to":{
                      name: 'OrganizationVariableConfig',
                      params: {
                        orgId: _vm.orgId,
                        organization_variable_id: data.item.id,
                      },
                    }}},[_c('span',{staticClass:"mdi mdi-settings"}),_vm._v(" 設定 ")]):_vm._e(),_c('b-button',{attrs:{"variant":"inverse-primary","to":_vm.getShowRoute(data.item)}},[_c('span',{staticClass:"mdi mdi-eye"}),_vm._v(" 查看 ")]),(_vm.hasModifyPermission())?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":_vm.getEditRoute(data.item)}},[_c('span',{staticClass:"mdi mdi-lead-pencil"}),_vm._v(" 編輯 ")]):_vm._e(),(_vm.hasModifyPermission())?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete"}),_vm._v(" 刪除 ")]):_vm._e()],1):_c('span',[(_vm.hasModifyPermission())?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.handleActivate(data.item)}}},[_vm._v(" 開通 ")]):_vm._e()],1)]}}])})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":10},on:{"change":_vm.getOranizationVariables},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }